import { Dialog, Transition } from '@headlessui/react'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import classNames from 'classnames'
import { Fragment, useCallback, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import styled from 'styled-components'

import { PaymentMethod } from '../../../../services/models/Subscription.model'
import { useBillingContext } from '../../../Billing/hooks/useBillingContext'
import { Button } from '../../../UI/Button'
import { Loader } from '../../../UI/Loader'
import { PaymentMethodForm } from '../PaymentMethodForm'

export interface PaymentMethodModalProps {
    paymentMethod?: PaymentMethod
    isOpen?: boolean
    onClose?(): void
}

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PKEY!)

const Styles = styled.div`
    button {
        min-height: unset;
        padding: 6px 10px;
    }

    div {
        .relative {
            width: 100% !important;
            ul {
                width: 100% !important;
            }
        }
    }
`

export const PaymentMethodModal = ({
    paymentMethod,
    isOpen,
    onClose,
}: PaymentMethodModalProps) => {
    const { attachPaymentMethod, deattachPaymentMethod, getPaymentMethod } =
        useBillingContext().paymentMethod
    const paymentFormRef = useRef<any>(null)
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const isUpdating = !!paymentMethod

    const onSubmit = useCallback(async () => {
        if (!paymentFormRef.current) {
            return
        }
        setIsLoading(true)

        try {
            // Create payment method
            const paymentMethodId = await paymentFormRef.current.onSubmit()

            // Attach new payment method
            if (paymentMethodId) {
                await attachPaymentMethod(paymentMethodId)
            }

            // Deattach previous payment method if it existed
            if (paymentMethod) {
                await deattachPaymentMethod(paymentMethod.id)
            }

            // Fetch updated payment method
            await getPaymentMethod()

            toast.success(
                isUpdating ? 'Updated payment method' : 'Created payment method'
            )
            onClose?.()
        } catch (error) {
            console.error(error)
            toast.error(
                `Failed to ${isUpdating ? 'update' : 'create'} payment method`
            )
        } finally {
            setIsLoading(false)
        }
    }, [
        isUpdating,
        paymentMethod,
        attachPaymentMethod,
        deattachPaymentMethod,
        getPaymentMethod,
        onClose,
    ])

    return (
        <Elements stripe={stripePromise}>
            <Transition.Root show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={onClose!}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <Styles className="fixed inset-0 z-10 w-screen overflow-y-visible">
                        <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative transform overflow-visible rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-md sm:p-6">
                                    <div className="flex">
                                        <div className="flex-1">
                                            <Dialog.Title
                                                as="h3"
                                                className="text-base font-semibold leading-6 text-gray-900 mb-2"
                                            >
                                                {isUpdating ? 'Update' : 'Add'}{' '}
                                                card
                                            </Dialog.Title>
                                        </div>
                                    </div>

                                    <div className="mt-4">
                                        <PaymentMethodForm
                                            ref={paymentFormRef}
                                        />
                                    </div>

                                    <div className="flex items-center justify-end mt-8">
                                        {isLoading ? (
                                            <Loader />
                                        ) : (
                                            <>
                                                <Button
                                                    intent="text"
                                                    className={classNames(
                                                        'text-sm ml-3'
                                                    )}
                                                    onClick={onClose}
                                                    label="Close"
                                                />
                                                <Button
                                                    intent="primary"
                                                    className={classNames(
                                                        'text-sm'
                                                    )}
                                                    label={
                                                        isUpdating
                                                            ? 'Update'
                                                            : 'Create'
                                                    }
                                                    onClick={onSubmit}
                                                />
                                            </>
                                        )}
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </Styles>
                </Dialog>
            </Transition.Root>
        </Elements>
    )
}
