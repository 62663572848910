import { AxiosResponse } from 'axios'

import authAxios from './auth.axios'

const baseUrl = process.env.REACT_APP_API

const getCannyIoSsoToken = (): Promise<string | null> => {
    const url = `${baseUrl}/services/cannyio`
    return new Promise((resolve, reject) => {
        authAxios
            .get(url)
            .then((response: AxiosResponse) => {
                resolve(response.data.ssoToken || null)
            })
            .catch((error) => reject(error))
    })
}

const isWhisperOverflowed = async (visitId: string): Promise<boolean> => {
    const url = `${baseUrl}/services/whisper/overflow?visitId=${visitId}`
    return new Promise((resolve) => {
        authAxios
            .get(url)
            .then((response: AxiosResponse) =>
                resolve(response.data.isOverflowed ?? false)
            )
            .catch(() => resolve(false))
    })
}

const getAzureSSTToken = async (): Promise<{
    token: string
    region: string
}> => {
    const url = `${baseUrl}/services/speech/token`
    return new Promise((resolve, reject) => {
        authAxios
            .get(url)
            .then((response: AxiosResponse) => resolve(response.data))
            .catch((error) => reject(error))
    })
}
export const servicesService = {
    getCannyIoSsoToken,
    isWhisperOverflowed,
    getAzureSSTToken,
}
