import * as speechsdk from 'microsoft-cognitiveservices-speech-sdk'
import { toast } from 'react-toastify'

import { DEFAULT_LANGUAGE, Language } from '../constants'
import {
    TranscribedContent,
    UpdateMediaDeviceState,
} from '../services/event-bus/events'
import { servicesService } from '../services/http/services.service'
import { transcriptionsService } from '../services/http/transcriptions.service'
import { Transcript } from '../services/models/Visit.model'
import { screenLock } from './screenLock'
import { timer } from './timer'

const isDev =
    window.location.hostname === 'localhost' ||
    window.location.hostname === 'dev.fluent.health'

export class AzureSTT {
    visitId: string | null = null
    deviceId?: string
    tokenObj: {
        token: string
        region: string
    } | null = null
    recognizer: speechsdk.SpeechRecognizer | null = null
    languageTarget?: Language

    constructor(visitId: string, deviceId?: string, languageTarget?: Language) {
        this.visitId = visitId
        this.deviceId = deviceId
        this.languageTarget = languageTarget ?? DEFAULT_LANGUAGE
    }

    async start() {
        if (!this.tokenObj) {
            // Emit media device state
            UpdateMediaDeviceState.emit({ isAvailable: false })

            this.tokenObj = await servicesService.getAzureSSTToken()
        }
        const speechConfig = speechsdk.SpeechConfig.fromAuthorizationToken(
            this.tokenObj.token,
            this.tokenObj.region
        )
        speechConfig.speechRecognitionLanguage = this.languageTarget ?? 'en-US'

        const audioConfig = this.deviceId
            ? speechsdk.AudioConfig.fromMicrophoneInput(this.deviceId)
            : speechsdk.AudioConfig.fromDefaultMicrophoneInput()
        this.recognizer = new speechsdk.SpeechRecognizer(
            speechConfig,
            audioConfig
        )

        this.recognizer.recognizing = (s, e) => {
            const content = e.result.text

            // Emit transcript content for component consumption
            TranscribedContent.emit({
                visitId: this.visitId!,
                transcript: {
                    content,
                    timestamp: new Date(),
                    isPartial: true,
                },
            })
        }

        this.recognizer.recognized = (s, e) => {
            if (e.result.reason === speechsdk.ResultReason.RecognizedSpeech) {
                const content = e.result.text
                const transcript: Transcript = {
                    content,
                    timestamp: new Date(),
                }

                // Emit transcript content for component consumption
                TranscribedContent.emit({
                    visitId: this.visitId!,
                    transcript: {
                        content,
                        timestamp: new Date(),
                    },
                })

                addTranscriptToVisit(this.visitId!, transcript)
                // } else if (e.result.reason === speechsdk.ResultReason.NoMatch) {
                //     setDisplayText('NOMATCH: Speech could not be recognized.')
            }
        }

        this.recognizer.sessionStarted = () => {
            // Emit media device state
            UpdateMediaDeviceState.emit({ isAvailable: true })

            if (isDev) {
                toast.success('Connected to Azure SST service')
            }
        }

        this.recognizer.canceled = () => {
            // setDisplayText(`CANCELED: Reason=${e.reason}`)
            this.recognizer?.stopContinuousRecognitionAsync()
        }

        this.recognizer.sessionStopped = () => {
            // setDisplayText('Session stopped.')
            this.recognizer?.stopContinuousRecognitionAsync()
        }

        // Start continuous recognition
        this.recognizer.startContinuousRecognitionAsync()
    }

    stop() {
        this.recognizer?.stopContinuousRecognitionAsync()
        this.recognizer = null

        // audio.stopStream()
        screenLock.unlock()
    }

    setToken(tokenObj: { token: string; region: string }) {
        this.tokenObj = tokenObj
    }
}

const addTranscriptToVisit = (
    visitId: string,
    transcript: Transcript
): Promise<void> => {
    const duration = timer.getSeconds()
    return transcriptionsService.createTranscript(visitId, transcript, duration)
}
